import React from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";

const TermsOfService = () => {
  return (
    <div className="pageContainer">
      <div className="solid_colour_container">
        <div className="faded_down_container">
          <Link to="/">
            <img className="lifememologo" src="lifememologo.png" alt="logo" />
          </Link>
        </div>
        <div className="privacy_policy_container">
          <h3 className="text" style={{ color: "#009fb7" }}>
            LifeMemo's Terms of Service
          </h3>
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <h4 className="text">Last Updated: </h4>
            <p className="text" style={{ marginLeft: 10 }}>
              2024-02-06
            </p>
          </span>
          <p className="privacy_policy_text">
            Welcome to LifeMemo! These Terms of Service ("Terms") govern your
            use of the LifeMemo mobile application ("App") provided by LifeMemo
            ("we," "us," or "our"). By accessing or using the App, you agree to
            be bound by these Terms. If you do not agree to these Terms, please
            do not access or use the App.
          </p>
          <h4 className="text">Description of LifeMemo</h4>
          <p className="privacy_policy_text">
            LifeMemo is a mobile application that allows users to create journal
            entries describing their day, including text, photos, and videos.
            The App takes this raw content and generates summaries of the user's
            day, identifies locations, companions, and activities using LLMs
            (Large Language Models) from third parties such as OpenAI. LifeMemo
            creates a timeline of the user's life, detects trips, provides
            monthly summaries, and offers an overview of the user's life to
            date. The App allows users to view memories based on locations and
            people you were with. Please note that LifeMemo is not intended to
            store highly sensitive data, and it is the user's responsibility to
            ensure that they do not upload highly sensitive data to the App.
          </p>
          <h4 className="text">Account Creation</h4>
          <p className="privacy_policy_text">
            To use LifeMemo, users must create an account through our Auth0
            flow, a highly secure third-party authentication service. Users can
            sign up using their gmail account or by setting up an email and
            password. If a user signs up using an email, they must verify their
            email before they can access the App's functionalities. By creating
            an account, users agree to abide by the terms outlined here.
          </p>
          <h4 className="text">User Conduct</h4>
          <p className="privacy_policy_text">
            Users must use LifeMemo in compliance with all applicable laws and
            regulations. Users are responsible for the content they upload to
            the App and must not upload any content that violates the rights of
            others or is unlawful, harmful, or offensive. Users must not attempt
            to interfere with the operation of the App or access it in
            unauthorized ways.
          </p>
          <h4 className="text">Privacy Policy</h4>
          <p className="privacy_policy_text">
            By using LifeMemo, you agree to our Privacy Policy, which explains
            how we collect, use, and protect your personal information. Please
            review our{" "}
            <a
              style={{ textDecoration: "none", color: "#009fb7" }}
              href="https://lifememoapp.com/privacypolicy"
            >
              Privacy Policy
            </a>{" "}
            to understand our practices.
          </p>
          <h4 className="text">Intellectual Property</h4>
          <p className="privacy_policy_text">
            All content and intellectual property associated with LifeMemo,
            including but not limited to text, images, videos, and trademarks,
            are owned by LifeMemo or our licensors. Users may not use,
            reproduce, or distribute any content from the App without our prior
            written consent.
          </p>
          <h4 className="text">Disclaimer of Warranties</h4>
          <p className="privacy_policy_text">
            LifeMemo is provided "as is" and "as available" without warranties
            of any kind, whether express or implied. We do not warrant that the
            App will be error-free, secure, or uninterrupted. Users use the App
            at their own risk.
          </p>
          <h4 className="text">Limitation of Liability</h4>
          <p className="privacy_policy_text">
            In no event shall LifeMemo be liable for any indirect, incidental,
            special, or consequential damages arising out of or in connection
            with the use of LifeMemo, even if we have been advised of the
            possibility of such damages.
          </p>
          <h4 className="text">Indemnification</h4>
          <p className="privacy_policy_text">
            Users agree to indemnify and hold harmless [Your Company Name], its
            affiliates, officers, directors, employees, and agents from any
            claims, damages, liabilities, and expenses (including attorneys'
            fees) arising out of or related to their use of LifeMemo or
            violation of these Terms.
          </p>
          <h4 className="text">Changes to Terms</h4>
          <p className="privacy_policy_text">
            We reserve the right to modify or update these Terms at any time
            without prior notice. Any changes will be effective immediately upon
            posting. It is the user's responsibility to review these Terms
            periodically for changes. Continued use of LifeMemo after the
            posting of changes constitutes acceptance of the modified Terms.
          </p>
          <h4 className="text">Contact Information</h4>
          <p className="privacy_policy_text">
            If you have any questions or concerns about these Terms, please
            contact us at lifememohello@gmail.com.
          </p>
        </div>
      </div>

      <div className="faded_container">
        <div style={{ marginTop: 30 }} />
        <Footer />
      </div>
    </div>
  );
};

export default TermsOfService;
