export interface WrappedResponse {
  response_json?: object;
  is_success: boolean;
  error_message?: string;
}

export const wrappedRequest = async (url: string, requestOptions: object) => {
  try {
    let response = await fetch(url, requestOptions);

    let response_json = await response.json();

    if (response.ok) {
      return {
        response_json: response_json,
        is_success: true,
        error_message: undefined,
      };
    } else {
      return {
        response_json: response_json,
        is_success: false,
        error_message: response_json.error,
      };
    }
  } catch (err) {
    return {
      response_json: undefined,
      is_success: false,
      error_message: undefined,
    };
  }
};
