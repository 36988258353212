import React from "react";
import DailyEntryTag from "./DailyEntryTag.tsx";

const MemorableMoments = ({
  memorableMoments,
}: {
  memorableMoments: string[];
}) => {
  if (memorableMoments.length == 0) {
    return <></>;
  }
  return (
    <div>
      <p className="memorable_moments_header lifememo_text">
        Memorable Moments
      </p>
      <div className="memorable_moments_item_container">
        {memorableMoments.map((moment, ix) => {
          return (
            <DailyEntryTag key={ix} text={moment} r={79} g={160} b={149} />
          );
        })}
      </div>
    </div>
  );
};

export default MemorableMoments;
