import React from "react";

const SomeoneSharedLink = ({ personName }: { personName?: string }) => {
  let message = `${
    personName ? personName : "Somebody"
  } shared their day with you on LifeMemo!`;

  return (
    <div className="someone_shared_container">
      <img
        src="/share_icon.png"
        alt="LifeMemo"
        width={400}
        className="someone_shared_icon"
      />
      <p className="lifememo_text someone_shared_text">{message}</p>
    </div>
  );
};

export default SomeoneSharedLink;
