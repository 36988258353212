export const prettifyDate = (yyyymmdd: string): string => {
  const year = Number(yyyymmdd.slice(0, 4));
  const month = Number(yyyymmdd.slice(4, 6)) - 1; // Subtract 1 because months are 0-indexed
  const day = Number(yyyymmdd.slice(6, 8));
  const date = new Date(year, month, day);
  return prettifyDateFromDateObject(date);
};

export const prettifyDateFromDateObject = (date: Date): string => {
  // Format the date as "20 Mar, '23"
  const options = { year: "2-digit", month: "short", day: "numeric" };
  // @ts-ignore
  const formattedDate = date.toLocaleDateString(undefined, options);

  // Combine the formatted date and the last two digits of the year
  const finalFormattedDate = formattedDate.replace(
    formattedDate.slice(-2),
    `'${formattedDate.slice(-2)}`
  );

  return finalFormattedDate;
};
