import { useEffect, useState } from "react";
import FeatureItem from "./FeatureItem";

const FeatureCarousel = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [imagesLoaded, setImagesLoaded] = useState(0);
  const [displayPlaceholder, setDisplayPlaceholder] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      setSelectedIndex((prevIndex) => prevIndex + 1);
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const onImageLoaded = () => {
    setImagesLoaded((imagesLoaded) => imagesLoaded + 1);
  };

  const allFeatures = [
    {
      title: "Describe your day",
      photo_urls: [
        "https://lifememo-publicbucket.s3.us-east-2.amazonaws.com/audio_entry_feature.png",
        "https://lifememo-publicbucket.s3.us-east-2.amazonaws.com/text_entry_feature.png",
      ],
      description: "Describe your day through audio or text, we do the rest",
    },
    {
      title: "Your day in a snapshot",
      photo_urls: [
        "https://lifememo-publicbucket.s3.us-east-2.amazonaws.com/daily_entry_feature_1.png",
        "https://lifememo-publicbucket.s3.us-east-2.amazonaws.com/daily_entry_feature_2.png",
      ],
      description:
        "We detect people, places and memorable moments and create a timeline of your day",
    },
    {
      title: "Life timeline",
      photo_urls: [
        "https://lifememo-publicbucket.s3.us-east-2.amazonaws.com/timeline_1_feature.png",
        "https://lifememo-publicbucket.s3.us-east-2.amazonaws.com/timeline_2_feature.png",
      ],
      description:
        "Scroll through an overview of your life so far with trips, monthly summaries and significant events",
    },
    {
      title: "Places",
      photo_urls: [
        "https://lifememo-publicbucket.s3.us-east-2.amazonaws.com/location_feature.png",
      ],
      description: "Revisit memories from places you've visited",
    },
    {
      title: "People",
      photo_urls: [
        "https://lifememo-publicbucket.s3.us-east-2.amazonaws.com/people_feature_1.png",
      ],
      description: "Relive memories based on who you were with",
    },
  ];

  let totalPhotos = allFeatures.length;

  const allImagesLoaded = imagesLoaded >= totalPhotos;

  return (
    <div>
      <img
        onLoad={() => setDisplayPlaceholder(true)}
        src="/mobile_placeholder.svg"
        alt="LifeMemo"
        width={400}
        style={{
          position: "relative",
          display:
            allImagesLoaded | (displayPlaceholder == false) ? "none" : "block",
        }}
      />

      {allFeatures.map((feature, ix) => {
        return (
          <FeatureItem
            key={ix}
            title={feature.title}
            photo_urls={feature.photo_urls}
            description={feature.description}
            isVisible={
              selectedIndex % allFeatures.length == ix && allImagesLoaded
            }
            onLoad={() => onImageLoaded()}
          />
        );
      })}
    </div>
  );
};

export default FeatureCarousel;
