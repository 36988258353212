import React from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";

const PrivacyPolicy = () => {
  return (
    <div className="pageContainer">
      <div className="solid_colour_container">
        <div className="faded_down_container">
          <Link to="/">
            <img className="lifememologo" src="lifememologo.png" />
          </Link>
        </div>
        <div className="privacy_policy_container">
          <h3 className="text" style={{ color: "#009fb7" }}>
            LifeMemo's Privacy Policy
          </h3>
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <h4 className="text">Last Updated: </h4>
            <p className="text" style={{ marginLeft: 10 }}>
              2024-02-06
            </p>
          </span>
          <p className="privacy_policy_text">
            LifeMemo ("we," "us," or "our") operates the LifeMemo mobile
            application (the "App"). This page informs you of our policies
            regarding the collection, use, and disclosure of personal data when
            you use our App and the choices you have associated with that data.
            We use your data to provide and improve the App. By using the App,
            you agree to the collection and use of information in accordance
            with this policy.
          </p>
          <h4 className="text">Information Collection And Use</h4>
          <p className="privacy_policy_text">
            We collect the email address provided during authentication via
            Auth0 solely for the purpose of identifying users. All other
            personal data, including journal entries and associated content, is
            stored securely and privately within our system and encrypted at
            rest. We do not collect any additional personal data about users.
          </p>
          <h4 className="text">Use of Data</h4>
          <p className="privacy_policy_text">
            We collect and store journal entries and associated content provided
            by users within the App. This information is used solely for the
            purpose of providing the journaling service. We may share journal
            entries with third parties to for the purpose of generating journal
            summaries. The data shared with these third parties will be
            anonymous and encrypted in transit and at rest. These summaries are
            used to improve the App's functionality and provide enhanced
            features to users.
          </p>
          <h4 className="text">Data Retention</h4>
          <p className="privacy_policy_text">
            We retain user data, including journal entries and associated
            content, until the user deletes their account. Upon account
            deletion, all user data is permanently removed from our systems.
          </p>
          <h4 className="text">Security</h4>
          <p className="privacy_policy_text">
            We take the security of user data seriously. All user data,
            including journal entries and associated content, is encrypted at
            rest to prevent unauthorized access or disclosure.
          </p>
          <h4 className="text">Chilren's Privacy</h4>
          <p className="privacy_policy_text">
            Our App is not directed at individuals under the age of 13, and we
            do not knowingly collect personal data from children under 13. If
            you are a parent or guardian and you are aware that your child has
            provided us with personal data, please contact us so that we can
            take necessary action.
          </p>
          <h4 className="text">Changes to This Privacy Policy</h4>
          <p className="privacy_policy_text">
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
          </p>
          <h4 className="text">Contact Us</h4>
          <p className="privacy_policy_text">
            If you have any questions about this Privacy Policy, please contact
            us at lifememohello@gmail.com
          </p>
        </div>
      </div>

      <div className="faded_container">
        <div style={{ marginTop: 30 }} />
        <Footer />
      </div>
    </div>
  );
};

export default PrivacyPolicy;
