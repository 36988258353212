import React from "react";

const DailyEntryTag = ({
  text,
  r,
  g,
  b,
}: {
  text: string;
  r: number;
  g: number;
  b: number;
}) => {
  return (
    <div
      className="daily_entry_tag"
      style={{
        backgroundColor: `rgba(${r}, ${g}, ${b}, 0.15)`,
        border: `1px solid rgba(${r}, ${g}, ${b}, 0.3)`,
      }}
    >
      <p
        className="daily_entry_tag_text lifememo_text"
        style={{ color: `rgba(${r}, ${g}, ${b}, 1)` }}
      >
        {text}
      </p>
    </div>
  );
};

export default DailyEntryTag;
