import React, { useState } from "react";
import "./contact_us.css";
import "./general.css";
import Footer from "../components/Footer";
import PulseLoader from "react-spinners/PulseLoader";
import { AppConfig } from "../app_config.tsx";

interface ContactFormData {
  subject: string;
  email: string;
  message: string;
}

const ContactUs = () => {
  const [emailSendSuccessfully, setEmailSendSuccessfully] = useState(false);
  const [errorSending, setErrorSending] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [formData, setFormData] = useState<ContactFormData>({
    subject: "",
    email: "",
    message: "",
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const requestSendEmail = async ({
    subject,
    email,
    message,
  }: {
    subject: string;
    email: string;
    message: string;
  }) => {
    setSendingEmail(true);

    try {
      let response = await fetch(`${AppConfig.API_DOMAIN}/contact/email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          subject,
          email,
          message,
        }),
      });

      if (response.ok) {
        setSendingEmail(false);
        setEmailSendSuccessfully(true);
        setTimeout(() => {
          setEmailSendSuccessfully(false);
          setFormData({
            subject: "",
            email: "",
            message: "",
          });
        }, 5000);
      } else {
        throw new Error("Failed to send");
      }
    } catch (err) {
      setSendingEmail(false);
      setErrorSending(true);
      setTimeout(() => {
        setErrorSending(false);
      }, 3000);
    }
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    requestSendEmail(formData);
  };

  return (
    <div className="pageContainer">
      <div className="solid_colour_container">
        <div className="faded_down_container">
          <img className="lifememologo" src="lifememologo.png" />
        </div>
        <div className="contact_form_container">
          <p className="lifememo_text prompt_text">
            Have feedback or questions? Get in touch!
          </p>
          <div
            className={`${sendingEmail ? "pulse_loader_container" : "hidden"}`}
          >
            <PulseLoader color={"white"} size={5} />
          </div>
          <p
            className={
              emailSendSuccessfully ? "success_message" : "error_message"
            }
          >
            {emailSendSuccessfully
              ? "Message sent - we will be in touch!"
              : errorSending
              ? "Error encountered sending message"
              : ""}
          </p>
          <form
            className={`${
              errorSending || sendingEmail || emailSendSuccessfully
                ? "hidden"
                : ""
            }`}
            onSubmit={handleSubmit}
          >
            <div>
              <div className="form_label_container">
                <label htmlFor="subject" className="form_label lifememo_text">
                  Subject
                </label>
              </div>
              <input
                type="text"
                id="subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                required
                className="form_input"
              />
            </div>
            <div>
              <div className="form_label_container">
                <label htmlFor="email" className="form_label lifememo_text">
                  Email
                </label>
              </div>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="form_input"
              />
            </div>
            <div>
              <div className="form_label_container">
                <label htmlFor="message" className="form_label lifememo_text">
                  Message
                </label>
              </div>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                rows={5}
                required
                className="form_input"
              />
            </div>
            <div className="button_container">
              <button type="submit" className="submit_button lifememo_text">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="faded_container">
        <Footer />
      </div>
    </div>
  );
};

export default ContactUs;
