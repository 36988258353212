import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { AppConfig } from "../app_config.tsx";
import { prettifyDate } from "../utils.tsx";
import "./daily_entries_share_view.css";
import "./general.css";
import EntryTimeline from "../components/EntryTimeline.tsx";
import { wrappedRequest } from "../util/requests.tsx";
import { IoIosAlert } from "react-icons/io";
import DotLoader from "react-spinners/DotLoader";
import MediaCarousel from "../components/MediaCarousel.tsx";
import DailyEntryTag from "../components/DailyEntryTag.tsx";
import { IoPersonSharp } from "react-icons/io5";
import { IoLocation } from "react-icons/io5";
import { capitalizeWords } from "../util/formatting.tsx";
import Footer from "../components/Footer.js";
import MemorableMoments from "../components/MemorableMoments.tsx";
import SomeoneSharedLink from "../components/SomeoneSharedLinkMessage.tsx";
import ShareDownloadPrompt from "../components/ShareDownloadPrompt.tsx";

export interface EntryLocation {
  town_city: string;
  country: string;
  two_letter_iso_country_code: string;
}

export interface TimelineEvent {
  event_name: string;
  description: string;
  icon: string;
}

export interface DateEntryId {
  date: string;
  entry_id: string;
}

export interface MentionedPerson {
  user_id: string;
  mentioned_person_id: string;
  first_name: string;
  family_name: string;
  description: string;
  count_mentioned: number;
  profile_picture?: string;
  mentioned_dates: DateEntryId[];
}

interface DailyEntry {
  date: string;
  user_id: string;
  entry_id: string;
  one_line_summary: string;
  where_was_i: EntryLocation[];
  places_visited: string[];
  memorable_moments: string[];
  timeline: TimelineEvent[];
  mentioned_people: MentionedPerson[];
  is_processing: boolean;
  source_audio_file?: string;
  source_text_file?: string;
}

export interface SignedMediaUrl {
  signed_url: string;
  signed_url_preview?: string;
  media_type: string;
  file_key: string;
}

const DailyEntryShareView = () => {
  const [isLoadingMedia, setIsLoadingMedia] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [mediaUrls, setMediaUrls] = useState([]);
  const [dailyEntry, setDailyEntry] = useState<DailyEntry | undefined>(
    undefined
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const loadMedia = async (shareId: string) => {
    setIsLoadingMedia(true);
    let response = await wrappedRequest(
      `${AppConfig.API_DOMAIN}/media/share_link?share_link_id=${shareId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "true",
        },
      }
    );

    if (response.is_success) {
      setMediaUrls(response.response_json.media_urls);
    }
    setIsLoadingMedia(false);
  };

  const loadDailyEntry = async (shareId?: string | null) => {
    setErrorMessage(undefined);
    if (shareId == undefined || shareId == null) {
      setErrorMessage("Invalid share ID");
      return;
    }

    setIsLoading(true);

    try {
      let response = await wrappedRequest(
        `${AppConfig.API_DOMAIN}/dailyentries/share?share_link_id=${shareId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": "true",
          },
        }
      );

      if (response.is_success) {
        setDailyEntry(response.response_json.daily_entry);
        loadMedia(shareId);
      } else {
        if (response.error_message) {
          setErrorMessage(response.error_message);
        } else {
          setErrorMessage("Failed to load entry");
        }
      }
    } catch (err) {
      setErrorMessage("Failed to load entry");
    } finally {
      setIsLoading(false);
    }
  };

  const searchId = searchParams.get("share_id");

  useEffect(() => {
    setDailyEntry(undefined);
    if (!searchId) {
      return;
    }

    loadDailyEntry(searchId);
  }, [searchId]);
  const DailyEntryBody = () => {
    if (!dailyEntry) {
      return <></>;
    }
    return (
      <div className="daily_entry_container">
        <div className="daily_entry_header lifememo_text">
          {prettifyDate(dailyEntry.date)}
        </div>
        <div className="daily_entry_title lifememo_text">
          {dailyEntry.one_line_summary}
        </div>
        {isLoadingMedia && mediaUrls.length == 0 ? (
          <div className="media_loader_container">
            <DotLoader color="#009fb7" size={20} />
          </div>
        ) : (
          <></>
        )}
        <div className="mentioned_people_container">
          {dailyEntry.mentioned_people.length > 0 ? (
            <IoPersonSharp
              size={20}
              color="rgba(163, 167, 228, 1)"
              style={{ marginRight: 5 }}
            />
          ) : (
            <></>
          )}
          {dailyEntry.mentioned_people.map((person, ix) => {
            let name =
              person.family_name != undefined && person.family_name != ""
                ? `${person.first_name} ${person.family_name}`
                : person.first_name;

            return (
              <DailyEntryTag key={ix} text={name} r={163} g={167} b={228} />
            );
          })}
        </div>
        <div className="locations_container">
          {dailyEntry.where_was_i.length > 0 ? (
            <IoLocation
              size={20}
              color="rgb(255,123,123)"
              style={{ marginRight: 5 }}
            />
          ) : (
            <></>
          )}
          {dailyEntry.where_was_i.map((location, ix) => {
            let hasTownCity =
              location.town_city != undefined && location.town_city != "";
            let hasCountry =
              location.country != undefined && location.country != "";

            let locationName;

            if (hasTownCity && hasCountry == false) {
              locationName = location.town_city;
            } else if (hasTownCity && hasCountry) {
              locationName = `${location.town_city}, ${location.country}`;
            } else {
              locationName = location.country;
            }

            return (
              <DailyEntryTag
                key={ix}
                text={capitalizeWords(locationName)}
                r={255}
                g={123}
                b={123}
              />
            );
          })}
        </div>
        <MediaCarousel signedMediaUrls={mediaUrls} />
        <MemorableMoments memorableMoments={dailyEntry.memorable_moments} />
        <EntryTimeline timelineEntries={dailyEntry.timeline} />
      </div>
    );
  };

  const ErrorMessage = () => {
    if (!errorMessage) {
      return <></>;
    }

    return (
      <div
        key={searchId}
        className="daily_entry_container"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 100,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <p className="lifememo_text lifememo_error">{errorMessage}</p>
          <IoIosAlert className="lifememo_error" style={{ marginLeft: 5 }} />
        </div>
      </div>
    );
  };

  const LoadingDisplay = () => {
    if (!isLoading) {
      return <></>;
    }

    return (
      <div
        className="daily_entry_container"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 100,
        }}
      >
        <DotLoader color="#009fb7" size={30} />
      </div>
    );
  };

  return (
    <div className="pageContainer">
      <div className="solid_colour_container">
        <div className="faded_down_container">
          <img className="lifememologo" src="/lifememologo.png" />
        </div>
        {dailyEntry ? <SomeoneSharedLink /> : <></>}
        <DailyEntryBody />
        <LoadingDisplay />
        <ErrorMessage />
        <div style={{ height: 50 }} />
        <ShareDownloadPrompt />
      </div>
      <div className="faded_container">
        <Footer />
      </div>
    </div>
  );
};

export default DailyEntryShareView;
