interface AppConfigType {
  API_DOMAIN: string;
}
const AppConfigDev: AppConfigType = {
  API_DOMAIN: "https://c096-111-220-193-57.ngrok-free.app",
};

const AppConfigProd: AppConfigType = {
  API_DOMAIN: "https://lifememoapp.com",
};

export const AppConfig =
  // @ts-ignore
  process.env.NODE_ENV == "development" ? AppConfigDev : AppConfigProd;
