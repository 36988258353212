import AppleLogo from "../app_store_black.svg";
import FeatureCarousel from "./FeatureCarousel";

const MainCallToAction = () => {
  return (
    <div className="top_section">
      <div className="main_description">
        <p style={{ position: "relative" }} className="main_header">
          Your Life, Captured.
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <p className="sub_header">
            People, places, small moments, big moments, and everything in
            between.
          </p>
        </div>

        <div className="carousel_container">
          <FeatureCarousel />
        </div>
        <p className="sub_header_bold">Capture your life, with LifeMemo</p>
        <div
          className="downloads"
          onClick={() =>
            (window.location.href =
              "https://apps.apple.com/ca/app/my-lifememo/id6474201193")
          }
        >
          <img src={AppleLogo} width={150} />
        </div>
      </div>
    </div>
  );
};

export default MainCallToAction;
