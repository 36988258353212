import React from "react";
import { TimelineEvent } from "../pages/daily_entries_share_view";

const WORD_TO_ICON_NAME: Record<string, string> = {
  breakfast: "food",
  dinner: "food",
  food: "food",
  restaurant: "food",
  running: "run",
  run: "run",
  walk: "walk",
  walking: "walk",
  exercise: "gym",
  gym: "gym",
  cooking: "food",
};

const getIconPath = (eventName: string): string | undefined => {
  if (WORD_TO_ICON_NAME.hasOwnProperty(eventName.toLocaleLowerCase())) {
    return `/timeline_icons/${
      WORD_TO_ICON_NAME[eventName.toLocaleLowerCase()]
    }.png`;
  }

  return undefined;
};

const EntryTimeline = ({
  timelineEntries,
}: {
  timelineEntries: TimelineEvent[];
}) => {
  const renderEntry = (eventName: string, description: string) => {
    let iconPath = getIconPath(eventName);
    return (
      <div className="title_description_box">
        <div className="daily_timeline_entry_event_name">
          {iconPath != undefined ? (
            <img src={iconPath} width={30} height={30} />
          ) : (
            <></>
          )}
          <p className="lifememo_text timeline_event_name_text">{eventName}</p>
        </div>
        <p className="lifememo_text daily_timeline_entry_event_description">
          {description}
        </p>
      </div>
    );
  };
  return (
    <div className="timeline_container">
      <h4 className="timeline_header lifememo_text">Timeline</h4>
      <div className="marker_line_container_start">
        <div>
          <div className="start_end_marker" />
          <div className="timeline_event_line_start" />
        </div>
      </div>
      {timelineEntries.map((entry, ix) => {
        return (
          <div key={ix} className="timeline_event_container">
            <div className="marker_line_container">
              <div className="timeline_event_marker" />
              <div className="timeline_event_line" />
            </div>
            {renderEntry(entry.event_name, entry.description)}
          </div>
        );
      })}
      <div className="start_end_marker" />
    </div>
  );
};

export default EntryTimeline;
