import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer">
      <Link style={{ textDecoration: "none" }} to="/privacypolicy">
        <p className="footer_text">Privacy Policy</p>
      </Link>
      <div
        style={{
          height: 40,
          borderColor: "white",
          borderLeft: "1px solid white",
          marginLeft: 15,
          marginRight: 15,
        }}
      ></div>
      <Link style={{ textDecoration: "none" }} to="/termsofservice">
        <p className="footer_text">Terms of Service</p>
      </Link>
      <div
        style={{
          height: 40,
          borderColor: "white",
          borderLeft: "1px solid white",
          marginLeft: 15,
          marginRight: 15,
        }}
      ></div>
      <Link style={{ textDecoration: "none" }} to="/contact">
        <p className="footer_text">Contact Us</p>
      </Link>
    </div>
  );
};

export default Footer;
