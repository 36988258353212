import { useMediaQuery } from "@react-hook/media-query";
import { useEffect, useState } from "react";

const FeatureItem = ({ title, description, photo_urls, isVisible, onLoad }) => {
  const [visiblePhotoIndex, setVisiblePhotoIndex] = useState(0);
  useEffect(() => {
    setVisiblePhotoIndex(0);
    const interval = setInterval(() => {
      setVisiblePhotoIndex((prevIndex) => prevIndex + 1);
    }, 5000);
    return () => clearInterval(interval);
  }, [isVisible]);
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <div
      className={`feature_container feature_slide ${
        isVisible ? "visible_feature" : "invisible_feature"
      }`}
    >
      {isMobile ? (
        <div className="feature_description_container_mobile">
          <p
            className="sub_green_header_mobile"
            style={{
              textAlign: "center",
              color: "rgb(134, 234, 215)",
            }}
          >
            {title}
          </p>
          <p
            className="text"
            style={{
              color: "white",
              textAlign: "center",
              fontSize: 14,
            }}
          >
            {description}
          </p>
        </div>
      ) : (
        <>
          {photo_urls.map((photo_url, index) => {
            return (
              <img
                key={index}
                className={`feature_mobile_image ${
                  index == visiblePhotoIndex % photo_urls.length
                    ? "feature_mobile_image_visible"
                    : "feature_mobile_image_invisible"
                }`}
                src={photo_url}
                alt="N/A"
                onLoad={() => onLoad()}
              />
            );
          })}
        </>
      )}

      {isMobile ? (
        <>
          {photo_urls.map((photo_url, index) => {
            return (
              <img
                key={index}
                className={`feature_mobile_image ${
                  index == visiblePhotoIndex % photo_urls.length
                    ? "feature_mobile_image_visible"
                    : "feature_mobile_image_invisible"
                }`}
                src={photo_url}
                alt="N/A"
                onLoad={() => onLoad()}
              />
            );
          })}
        </>
      ) : (
        <div
          style={{ maxWidth: 200 }}
          className="feature_description_container"
        >
          <h3
            className="sub_green_header"
            style={{
              marginTop: 100,
              width: 200,
              minWidth: 200,
              fontSize: 22,
              fontWeight: "bold",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              textAlign: "left",
            }}
          >
            {title}
          </h3>
          <p
            className="text"
            style={{ color: "white", textAlign: "left", fontSize: 16 }}
          >
            {description}
          </p>
        </div>
      )}
    </div>
  );
};

export default FeatureItem;
