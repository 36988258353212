import React, { useState } from "react";
import PulseLoader from "react-spinners/PulseLoader";

const MediaImage = ({
  imageUrl,
  imagePreviewUrl,
  isVisible,
}: {
  imageUrl: string;
  imagePreviewUrl?: string;
  isVisible: boolean;
}) => {
  const [displayPreviewImage, setDisplayPreviewImage] = useState(true);
  const showPreviewImage = displayPreviewImage && imagePreviewUrl && isVisible;
  const showMainImage = !showPreviewImage && isVisible;
  const [mainImageLoaded, setMainImageLoaded] = useState(false);
  const [previewImageLoaded, setPreviewImageLoaded] = useState(false);
  const showLoadingSpinner =
    (showMainImage && !mainImageLoaded) ||
    (showPreviewImage && !previewImageLoaded && isVisible);

  const onMainImageLoad = () => {
    setDisplayPreviewImage(false);
    setMainImageLoaded(true);
  };

  const onPreviewImageLoad = () => {
    setPreviewImageLoaded(true);
  };

  return (
    <div className="media_item_container">
      {showLoadingSpinner ? (
        <div className="image_loader_container">
          <PulseLoader color={"rgba(0, 0, 0, 0.1)"} size={12} />
        </div>
      ) : (
        <></>
      )}

      <img
        onLoad={onPreviewImageLoad}
        className={
          showPreviewImage
            ? "media_carousel_media"
            : "media_carousel_media_hidden"
        }
        src={imagePreviewUrl}
      />
      <img
        className={
          showMainImage ? "media_carousel_media" : "media_carousel_media_hidden"
        }
        src={imageUrl}
        onLoad={onMainImageLoad}
      />
    </div>
  );
};

export default MediaImage;
