import React, { useState } from "react";
import PulseLoader from "react-spinners/PulseLoader";

const MediaVideo = ({
  videoUrl,
  isVisible,
}: {
  videoUrl: string;
  isVisible: boolean;
}) => {
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(true);

  return (
    <div className="media_item_container">
      {showLoadingSpinner && isVisible ? (
        <div className="image_loader_container">
          <PulseLoader color={"rgba(0, 0, 0, 0.1)"} size={12} />
        </div>
      ) : (
        <></>
      )}
      <div className={"video_container"}>
        <video
          style={{ zIndex: isVisible ? 1000 : -1, position: "relative" }}
          src={videoUrl}
          onCanPlay={() => {
            setShowLoadingSpinner(false);
          }}
          onLoadedMetadata={() => {
            if (showLoadingSpinner) {
              setShowLoadingSpinner(false);
            }
          }}
          className={
            isVisible && !showLoadingSpinner
              ? "media_carousel_media_video"
              : "media_carousel_media_hidden"
          }
          controls={true}
        ></video>
      </div>
    </div>
  );
};

export default MediaVideo;
