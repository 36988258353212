import React from "react";
// @ts-ignore
import AppleLogo from "../app_store_black.svg";

const ShareDownloadPrompt = () => {
  return (
    <div className="share_link_download_container">
      <p className="download_text_prompt lifememo_text">
        LifeMemo makes capturing your life effortless.
      </p>
      <p className="download_text_prompt lifememo_text">
        Interested in trying it out?
      </p>
      <div
        className="downloads"
        onClick={() =>
          (window.location.href =
            "https://apps.apple.com/ca/app/my-lifememo/id6474201193")
        }
      >
        <img src={AppleLogo} className="download_logo" />
      </div>
    </div>
  );
};

export default ShareDownloadPrompt;
