import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/home.tsx";
import PrivacyPolicy from "./pages/privacy_policy.tsx";
import TermsOfService from "./pages/terms_of_service.tsx";
import DailyEntryShareView from "./pages/daily_entries_share_view.tsx";
import ContactUs from "./pages/contact_us.tsx";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/termsofservice" element={<TermsOfService />} />
        <Route
          path="/dailyentries/share/view"
          element={<DailyEntryShareView />}
        />
        <Route path="/contact" element={<ContactUs />} />
      </Routes>
    </Router>
  );
}

export default App;
