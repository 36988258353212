import React from "react";
import "./home.css";
import "./general.css";
import MainCallToAction from "../components/MainCallToAction";
import Footer from "../components/Footer";

const Home = () => {
  return (
    <div className="pageContainer">
      <div className="solid_colour_container">
        <div className="faded_down_container">
          <img className="lifememologo" src="/lifememologo.png" />
        </div>
        <MainCallToAction />
      </div>
      <div className="faded_container">
        <Footer />
      </div>
    </div>
  );
};

export default Home;
