import React, { useState } from "react";
import { SignedMediaUrl } from "../pages/daily_entries_share_view";
import MediaImage from "./MediaImage.tsx";
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import MediaVideo from "./MediaVideo.tsx";
import { useSwipeable } from "react-swipeable";

const MediaCarousel = ({
  signedMediaUrls,
}: {
  signedMediaUrls: Array<SignedMediaUrl>;
}) => {
  const [visibleIndex, setVisibleIndex] = useState(0);

  const incrementVisibleIndexDown = () => {
    if (visibleIndex == 0) {
      setVisibleIndex(signedMediaUrls.length - 1);
    } else {
      setVisibleIndex(visibleIndex - 1);
    }
  };

  const incrementVisibleIndexUp = () => {
    if (visibleIndex == signedMediaUrls.length - 1) {
      setVisibleIndex(0);
    } else {
      setVisibleIndex(visibleIndex + 1);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => incrementVisibleIndexUp(),
    onSwipedRight: () => incrementVisibleIndexDown(),

    delta: 10, // min distance(px) before a swipe starts. *See Notes*
    preventScrollOnSwipe: true, // prevents scroll during swipe (*See Details*)
    trackTouch: true, // track touch input
    trackMouse: true, // track mouse input
    rotationAngle: 0, // set a rotation angle
    swipeDuration: Infinity, // allowable duration of a swipe (ms). *See Notes*
    touchEventOptions: { passive: true }, // options for touch listeners (*See Details*)
  });

  if (signedMediaUrls.length == 0) {
    return <></>;
  }

  let images = signedMediaUrls.filter(
    (mediaUrl) => mediaUrl.media_type == "IMAGE"
  );

  let videos = signedMediaUrls.filter(
    (mediaUrl) => mediaUrl.media_type != "IMAGE"
  );

  return (
    <div className="media_carousel_container" {...handlers}>
      <div onClick={incrementVisibleIndexDown}>
        <FaChevronLeft size={40} className="chevron_swiper" />
      </div>
      <div className="media_item_container_centerer">
        {images.map((signedUrl, ix) => {
          return (
            <MediaImage
              key={ix}
              imageUrl={signedUrl.signed_url}
              imagePreviewUrl={signedUrl.signed_url_preview}
              isVisible={ix == visibleIndex}
            />
          );
        })}
        {videos.length > 0 ? (
          <MediaVideo
            isVisible={visibleIndex >= images.length}
            videoUrl={
              videos[Math.max(visibleIndex - images.length, 0)].signed_url
            }
          />
        ) : (
          <></>
        )}
      </div>
      <div onClick={incrementVisibleIndexUp}>
        <FaChevronRight size={40} className="chevron_swiper" />
      </div>
    </div>
  );
};

export default MediaCarousel;
